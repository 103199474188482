import type { HandleClientError } from '@sveltejs/kit';
import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_SENTRY_DSN, PUBLIC_SENTRY_ENVIRONMENT } from '$env/static/public';

if (PUBLIC_SENTRY_DSN) {
	Sentry.init({
		dsn: PUBLIC_SENTRY_DSN,
		environment: PUBLIC_SENTRY_ENVIRONMENT || 'production'
	});
}

export const handleError: HandleClientError = async ({ error, event, status, message }) => {
	// Generate a random error ID to use for error matching
	const errorId = crypto.randomUUID();

	// Capture the error with Sentry
	// @see https://docs.sentry.io/platforms/javascript/guides/sveltekit/
	if (PUBLIC_SENTRY_DSN) {
		Sentry.captureException(error, {
			contexts: { sveltekit: { event, errorId, status, message } }
		});
	}

	return {
		message: 'An unexpected error occurred. We are working on it, please try again later.',
		errorId
	};
};
